// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-brand-identity-js": () => import("./../../../src/pages/about/brand-identity.js" /* webpackChunkName: "component---src-pages-about-brand-identity-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-sustainability-js": () => import("./../../../src/pages/about/sustainability.js" /* webpackChunkName: "component---src-pages-about-sustainability-js" */),
  "component---src-pages-about-who-we-are-js": () => import("./../../../src/pages/about/who-we-are.js" /* webpackChunkName: "component---src-pages-about-who-we-are-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-derogel-js": () => import("./../../../src/pages/products/derogel.js" /* webpackChunkName: "component---src-pages-products-derogel-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-overview-js": () => import("./../../../src/pages/products/overview.js" /* webpackChunkName: "component---src-pages-products-overview-js" */),
  "component---src-pages-products-serogel-js": () => import("./../../../src/pages/products/serogel.js" /* webpackChunkName: "component---src-pages-products-serogel-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

